import React, { useState } from "react";

//React Icons
import { AlignJustify, X } from "lucide-react";

import Logo2 from "../assests/Futurbits_logo.svg";
import MobileNav from "./MobileNav";

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);

  const closeMobileNav = () => {
    setNavOpen(false);
  };
  return (
    <>
      <nav className="w-full py-3  md:py-3 flex flex-row items-center justify-between fixed hrefp-0 shadow-sm backdrop-blur-lg  z-50 font-poppins bg-[#000000]/30">
        {/*------------------Logo--------------------------*/}
        <a href={"/"}>
          <div className=" ml-6 md:ml-10 lg:ml-20 cursor-pointer flex flex-col items-center select-none">
            <img src={Logo2} alt="logo" />
          </div>
        </a>

        {/*------------------NAV a--------------------------*/}

        <div className="flex flex-row items-center justify-between lg:justify-start  mr-6 md:mr-10 lg:mr-20">
        <a href="#features">
          <div className=" underline-animation ml-4 px-3 py-1 text-neutral-300 rounded-lg hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
              Solutions
            </div>
          </a>
          
          
          
          <a href="#integrations">
          <div className=" underline-animation ml-4 px-3 py-1 text-neutral-300 rounded-lg hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
              Integrations
            </div>
          </a>
          <a href="#why-futurebits">
            <div className=" underline-animation ml-4 px-3 py-1 text-neutral-300 rounded-lg hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
              Why Us
            </div>
          </a>
          <a href="#testimonals">
          <div className=" underline-animation ml-4 px-3 py-1 text-neutral-300 rounded-lg hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 hidden lg:flex  cursor-pointer">
              Testimonials
            </div>
          </a>
          <a
            href="https://cal.com/futurebits"
            target="_blank"
            rel="noreferrer noopener"
            className="ml-4"
          >
            <button className="shine hidden md:flex text-white font-medium text-sm px-6 py-[8px] gradient-blue rounded-md hover:scale-105 active:scale-95">
              Book a Call
            </button>
          </a>

          {/*------------------------Live Portfolio------------------------ */}

          {/*-----------------Mobile Nav hrefggle--------------- */}
          <div
            onClick={() => setNavOpen(!navOpen)}
            className="text-neutral-50  ml-10 cursor-pointer
            flex lg:hidden"
          >
            {navOpen ? <X size={25} /> : <AlignJustify size={25} />}
          </div>
        </div>
      </nav>
      {navOpen && <MobileNav navOpen={navOpen} closeMobileNav={closeMobileNav} />}
    </>
  );
};
export default Navbar;
