import React from "react";
import styles from "../marquee/marquee.module.css";
const data = [
  {
    text: "Backtesting",
    desc: "Rigorously test strategies on historical data before deploying them in live markets.",
  },
  {
    text: "Forward Testing",
    desc: "Validate strategies with simulated trades in real-time before going live.",
  },
  {
    text: "Automated Strategies",
    desc: "Implement and execute rule-based trading strategies without manual intervention.",
  },
  {
    text: "Real-time Screeners",
    desc: "Filter and identify trading opportunities dynamically as market conditions evolve.",
  },
  {
    text: "Advanced Reports",
    desc: "Gain deep insights into performance, risk, and strategy behavior with comprehensive analytics.",
  },
  {
    text: "Research",
    desc: "Leverage our expertise to uncover new trading opportunities and refine existing strategies.",
  },
  {
    text: "Consulting",
    desc: "Benefit from our guidance to optimize your automated trading systems and processes.",
  },
  {
    text: "Automation Monitoring ",
    desc: "Ensure seamless operation with proactive monitoring and maintenance of your automated systems.",
  },
  {
    text: "Tradingview Customisations",
    desc: "Tailor Tradingview to your specific needs with custom indicators, strategies, and layouts.",
  },
  {
    text: "Tech Maintenance",
    desc: "Keep your trading infrastructure up-to-date and running smoothly with our technical support.",
  },
  {
    text: "Anthing else ",
    desc: "Have something else in mind, that is not covered? Happy to explore",
  },
];
const Solution = () => {
  return (
    <div className={`px-4 md:px-20 lg:px-40 pt-10 lg:pt-12 `}>
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-12 md:gap-x-10 md:gap-y-10 ">
        {data.map((ele, i) => {
          return (
            <div key={i} className={`h-[100px] md:h-[110px] lg:w-[370px]  rounded-2xl bg-gradient-to-b from-[#ffffff2f] via-[#1b1b1b]/0 to-[#01B0EA]/20  hover:bg-gradient-to-b hover:from-[#FFFFFF4D] hover:via-[#77D5F400]/0 hover:to-[#01B0EA] p-[1px] hover:shadow-2xl hover:shadow-gray-700/20 hover:-translate-y-4 hover:-skew-x-2  hover:scale-105 ease-linear duration-150`}>
              <div
                
                className={` bg-[#1b1b1be1] min-w-full h-full  rounded-2xl  cursor-pointer`}
              >
                <div className="px-1 bg-gradient-to-b from-[#1b1b1b] from-10% via-[#1e1e1e] via-50% to-[#1e1e1e] to-90% hover:bg-gradient-to-b hover:from-[#1b1b1b] from-10% hover:via-[#1b1a1a] via-50% hover:to-[#1b1a1a] to-90% -mt-2  absolute md:-mt-4 md:ml-3 z-10 ">
                  <div className="text-white px-2 text-[10px] flex-wrap  md:w-full  leading-[16px]  md:text-[19px] md:leading-[32px] font-sans font-semibold rounded-xl">
                    {ele.text}
                  </div>
                </div>
                <div className="h-full flex items-center text-[9px] leading-[14px] text-[#ffffff]/70 md:text-[13px] md:items-center md:leading-[22px] font-light font-poppins px-3  md:px-6 md:py-6">
                  {ele.desc}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Solution;
