import React, { useRef, useState, useEffect } from "react";

import styles from "../HeroSection/heroSection.module.css";
import Logo from "../assests/Futurbits_logo.svg";

import Location from "../assests/location.svg";
import Email from "../assests/Email.svg";
import PhoneNumber from "../assests/phoneNumber.svg";
import India from "../assests/india.jpeg";
import Dubai from "../assests/dubai.webp";
import { FaCheckCircle   } from "react-icons/fa";

import axios from "axios";

const NewFooter = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isSubmit, setIsSubmit] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
  };


  const handleSubmit = () => {
  
    setIsLoading(true);

    let data = JSON.stringify({
      name: name,
      email: email,
      message: message,
    });
    
    let config = {
      method: "post",
      url: "https://server.sypto.xyz/sypto/api/future-bits",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setIsSubmit(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

    
  };

  useEffect(() => {
    if (isSubmit) {
      setIsSubmit(true);
      const toRef = setTimeout(() => {
        setIsSubmit(false);
        setEmail("");
        setMessage("");
        setName("");
        clearTimeout(toRef);
      }, 4000);
    }
  }, [isSubmit]);

  return (
    <div id="Footer" className=" w-full bg-black">
      <div
        className="py-8 md:py-28 flex flex-row justify-center"
        id="connect-now"
      >
        <div className="w-[90%] md:w-[80%] ">
          <div className=" flex flex-col md:space-y-10 lg:space-y-0 lg:flex-row justify-between ">
            <div className="w-full lg:w-[30%] px-8 md:px-1 lg:px-0 ">
              <div className="-ml-3">
                <a href={"/"}>
                  <div className=" cursor-pointer flex flex-row items-center select-none">
                    <img src={Logo} alt="logo" />
                  </div>
                </a>
              </div>
              <div className="flex flex-row space-x-6 items-center mt-5 md:mt-8 ">
                <div>
                  <img
                    src={Location}
                    alt="Loaction"
                    className=" w-[17px] md:w-[20px]"
                  />
                </div>
                <div className="">
                  <div className="flex flex-row ml-1">
                    <img
                      src={India}
                      alt="India"
                      className="w-[25px] md:w-[40px]"
                    />
                    <img
                      src={Dubai}
                      alt="Dubai"
                      className="w-[25px] md:w-[40px] ml-5"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row space-x-6 mt-5 md:mt-8 items-center">
                <div className="">
                  <img
                    src={Email}
                    alt="Email"
                    className=" w-[20px] md:w-[20px]"
                  />
                </div>

                <a
                  className="ml-6"
                  href="mailto:admin@futurebits.tech"
                  target="_blank"
                >
                  <div className="text-[#ffffff]/70 text-sm md:text-[18px]  hover:underline underline-offset-2 font-light font-poppins">
                    admin@futurebits.tech
                  </div>
                </a>
              </div>
              <div className="flex flex-row space-x-6 items-center mt-5 md:mt-8">
                <div className="">
                  <img
                    src={PhoneNumber}
                    alt="Phone Number"
                    className=" w-[20px] md:w-[20px]"
                  />
                </div>

                <a
                  href="https://wa.me/+971585165671"
                  target="_blank"
                  className="ml-6 cursor-pointer"
                >
                  <div className="mt-1 text-[#ffffff]/70 text-sm md:text-[18px] hover:underline underline-offset-2 font-light font-poppins">
                    +971585165671
                  </div>
                </a>
              </div>
            </div>
            <div
              className={`relative w-full lg:w-[70%] px-8 md:px-1 lg:px-12 mt-10 md:mt-0 `}
            >
              <div className="font-sans text-[30px] leading-[48px] font-bold text-white">
                Let’s Talk
              </div>
              <div className="flex flex-col md:flex-row justify-between ">
                <div className=" w-full md:w-[45%] border-b-[1px] border-[#ffffff]/20 mt-6">
                  <input
                    type="text"
                    placeholder={`Enter your name here`}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    className={`${styles.logininput} focus:outline-none  `}
                  />
                
                {/* {isError && <span className="text-sm font-[400] text-red-500">Name cannot be empty</span>} */}
                </div>
                <div
                  className={
                    "w-full md:w-[45%] border-b-[1px] border-[#ffffff]/20 mt-6"
                  }
                >
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email here"
                    onChange={handleEmailChange}
                    value={email}
                    className={`${styles.logininput} focus:outline-none `}
                  />
                </div>
              </div>

              <div className="border-b-[1px] border-[#ffffff]/20 mt-6">
                <textarea
                  type="text"
                  placeholder="Enter your message here"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  className={` ${styles.logininput} focus:outline-none `}
                  style={{ height: "90px" }}
                />
              </div>

              {isSubmit ? (
                <div className={`${isSubmit ? "animate-slideFromRight transition-all duration-150 ease-linear " :""} z-20 absolute bottom-0 left-0 lg:left-12 bg-green-200 rounded-lg flex items-center space-x-2 md:space-x-2 mt-4 text font-medium px-4 py-4`}>
                  <FaCheckCircle   className="h-5 w-5 md:h-6 md:w-6 lg:w-6 g:h-6 text-green-600" />
                  <span className="text-[12px]  md:text-[16px] lg:text-[16px] text-emerald-700 font-poppins  font-[500]">
                  Thanks for your interest, we’ll get back ASAP!
                  </span>
                </div>
              ) : (
                ""
              )}

              <div className="flex flex-row mt-8 md:mt-8">
                <button
                  onClick={handleSubmit}
                  disabled={name === "" || email === "" || message === "" || email.includes("@") === false}
                  className=" disabled:cursor-not-allowed shine text-white font-medium text-lg px-12 w-full md:w-[250px] md:px-16 py-3 gradient-blue rounded-lg hover:scale-105 active:scale-95"
                >
                  {isloading === true ? (
                    <span className="flex flex-row justify-center">
                      <svg
                        className="h-10 w-10 text-[#ffffff] animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  ) : (
                    "Submit Query"
                  )}{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*------------------------Lower----------------------------- */}
      <div className="w-full max-w-screen-xl mx-auto flex flex-row justify-center items-center  text-center py-4 border-t border-neutral-900">
        <div className="text-[10px] md:text-sm  text-white/60 font-light font-Raleway">
          © 2024 Copyright by Futurebits.
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
