import React from "react";
import styles from "./heroSection.module.css";
import ConnectNow from "../common/connectNow";
import Navbar from "./Navbar";
import { TypeAnimation } from "react-type-animation";
import {motion} from "framer-motion"

const HeroSection = (props) => {
  return (
    <div
      initial={{ opacity: 0, scale: 0.8 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.7, delay: 0.2 }}
      className={`bg-[#000000] ${styles.backgroundHero}`}
    >
      <Navbar />
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2}} 
      className="  flex flex-col justify-center space-y-8 items-center py-28 md:py-44">
        <div className="px-8 hidden lg:block md:px-1 font-sans text-center">
          <div className="font-extrabold  text-white text-[20px] md:text-xl md:text-[52px] md:leading-[76px]">
            Boost your
            <TypeAnimation
              preRenderFirstString={true}
              sequence={["Trading", 3000, "Investment", 3000]}
              className="text-[#01B0EA] mx-3"
              speed={150}
              // speed={{type: 'keyStrokeDelayInMs', value: 100}}
              // cursor={false}
              deletionSpeed={150}
              wrapper="span"
              repeat={Infinity}
            />
            Performance
          </div>
          <div className="font-extrabold text-white text-[20px] md:text-xl md:text-[52px] md:leading-[76px] mt-1 md:mt-0">
            {" "}
            with Technology & Automation
          </div>
        </div>

        <div className="px-8 md:px-16 lg:px-1 lg:hidden font-sans text-center">
          <div className="font-extrabold  text-white text-[20px] md:text-[44px] md:leading-[76px]">
            Boost your
            <TypeAnimation
              preRenderFirstString={true}
              sequence={["Trading", 3000, "Investment", 3000]}
              className="text-[#01B0EA] mx-3"
              speed={150}
              // speed={{type: 'keyStrokeDelayInMs', value: 100}}
              // cursor={false}
              deletionSpeed={150}
              wrapper="span"
              repeat={Infinity}
            />
          </div>
          <div className="font-extrabold text-white text-[20px] md:text-[44px] md:leading-[76px] mt-1 md:mt-0">
            {" "}
            Performance with Technology & Automation
          </div>
        </div>

        <div className=" text-center text-[13px] leading-[24px]  md:text-[18px] md:leading-[32px] w-[90%] md:w-[85%]  lg:w-[50%] text-[#ffffff]/50 font-poppins">
          Transform your wealth-building strategies with FutureBits - where
          expert human insights are amplified with effective and efficient
          automations.
        </div>

        <div className="font-sans text-sm px-10 md:px-2 md:text-xl flex flex-wrap justify-center items-center text-[#01B0EA] font-semibold">
          <div className="px-3 md:px-6 cursor-pointer hover:scale-110 transition duration-150 ease-linear leading-6">
            Stocks
          </div>{" "}
          |{" "}
          <div className="px-3 md:px-6 cursor-pointer hover:scale-110 transition duration-150 ease-linear leading-6">
            Futures
          </div>{" "}
          |{" "}
          <div className="px-3 md:px-6 cursor-pointer hover:scale-110 transition duration-150 ease-linear leading-6">
            Commodities{" "}
          </div>{" "}
          |{" "}
          <div className="px-3 md:px-6 cursor-pointer hover:scale-110 transition duration-150 ease-linear leading-6">
            Options
          </div>{" "}
          |
          <div className="px-3 md:px-6 cursor-pointer hover:scale-110 transition duration-150 ease-linear leading-6">
            Forex
          </div>{" "}
          |
          <div className="px-3 md:px-6 cursor-pointer hover:scale-110 transition duration-150 ease-linear leading-6">
            Crypto
          </div>
        </div>
        <div className="md:hidden "></div>
        <div className="flex flex-row items-center justify-center md:pt-10">
          <ConnectNow />
        </div>
      </motion.div>
    </div>
  );
};
export default HeroSection;
